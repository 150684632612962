

.RS-faq {
  width: 100%;
  padding: unset;
  flex-direction: column;
  display: flex;
}

.RS-faq__single {
  gap: var(--RS-space-16);
  -webkit-tap-highlight-color: transparent;
  flex-direction: column;
  display: flex;
  position: relative;
}

.RS-faq__single:after {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  background-color: var(--RS-light-grey-color);
  border-radius: var(--RS-space-2);
  bottom: calc(0px + var(--RS-space-1-n));
  position: absolute;
}

.RS-faq__single-question {
  cursor: pointer;
  padding: var(--RS-space-24) var(--RS-space-8);
  -webkit-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .RS-faq__single-question {
    padding: var(--RS-space-24);
  }
}

.RS-faq__single-answer {
  gap: var(--RS-space-16);
  padding: 0 var(--RS-space-8) var(--RS-space-32) var(--RS-space-8);
  flex-direction: column;
  display: none;
}

@media (min-width: 768px) {
  .RS-faq__single-answer {
    padding: 0 var(--RS-space-32) var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-faq__single-answer--expanded {
  display: flex;
}

.RS-faq__single-answer p {
  line-height: var(--RS-line-height-xlg);
  font-size: var(--RS-font-size-p-desktop);
}

.RS-faq-icon {
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  margin-right: unset;
  transform: translateY(var(--RS-space-8));
}

@media (min-width: 768px) {
  .RS-faq-icon {
    margin-right: var(--RS-space-16);
  }
}

.RS-faq-icon--expanded {
  transform: translateY(var(--RS-space-8)) rotateZ(180deg);
}

.RS-mailchimp {
  transition-timing-function: var(--RS-default-transition);
  transform-style: preserve-3d;
  margin: var(--RS-space-32) auto;
  background: var(--RS-light-grey-color);
  width: 100%;
  border-radius: var(--RS-space-6);
  font-weight: var(--RS-font-weight-variable-bold);
  --calendlyWidth: 678px;
  max-width: calc(var(--calendlyWidth)  + var(--RS-space-64) * 2);
  font-size: var(--RS-font-size-p-desktop);
  justify-content: center;
  align-items: center;
  transition-property: transform, opacity;
  transition-duration: .5s;
  display: flex;
}

.RS-mailchimp__form-wrapper {
  padding: var(--RS-space-32) 0;
  flex-direction: column;
  display: flex;
}

.RS-mailchimp__field-group {
  gap: var(--RS-space-16);
  margin-bottom: var(--RS-space-32);
  flex-direction: column;
  display: flex;
}

@media (min-width: 768px) {
  .RS-mailchimp__field-group {
    flex-direction: row;
    align-items: center;
  }
}

.RS-mailchimp__field-group label {
  min-width: var(--RS-space-128);
}

.RS-mailchimp__field-group input {
  border: unset;
  outline: unset;
  padding: var(--RS-space-4);
  border-radius: var(--RS-space-2);
}

.RS-mailchimp__form-wrapper .RS-button {
  cursor: pointer;
  outline: unset;
  border: unset;
  margin-bottom: var(--RS-space-32);
}

.RS-mailchimp__form-wrapper .brandingLogo {
  max-width: calc(var(--RS-space-128)  + var(--RS-space-64)  + var(--RS-space-32));
  margin: 0 auto;
}



/*# sourceMappingURL=index.79f3d7cb.css.map */
