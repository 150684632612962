/* Calendly */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-mailchimp{
  display: flex;
  justify-content: center;
  align-items: center;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: 0.5s;
  transition-property: transform, opacity;
  transform-style: preserve-3d;
  margin: var(--RS-space-32) auto;
  background: var(--RS-light-grey-color);
  width: 100%;
  border-radius: var(--RS-space-6);

  font-weight: var(--RS-font-weight-variable-bold);

  --calendlyWidth: 678px;
  max-width: calc(var(--calendlyWidth) + var(--RS-space-64)*2);

  font-size: var(--RS-font-size-p-desktop);
}


.RS-mailchimp__form-wrapper{
  display: flex;
  flex-direction: column;
  /* gap: var(--RS-space-32); */
  padding: var(--RS-space-32) 0;
;}

.RS-mailchimp__field-group{
  display: flex;
  gap: var(--RS-space-16);
  
  margin-bottom: var(--RS-space-32);
  flex-direction: column;

  @media (--md-viewport) {
    flex-direction: row;
    align-items: center;
  }
}

.RS-mailchimp__field-group label{
  min-width: var(--RS-space-128);
}

.RS-mailchimp__field-group input{
  border: unset;
  outline: unset;
  padding: var(--RS-space-4);
  border-radius: var(--RS-space-2);
}

.RS-mailchimp__form-wrapper .RS-button{
  cursor: pointer;
  outline: unset;
  border: unset;
  margin-bottom: var(--RS-space-32);
  /* width: 100%; */
}

.RS-mailchimp__form-wrapper .brandingLogo{
  max-width: calc(var(--RS-space-128) + var(--RS-space-64) + var(--RS-space-32));
  margin: 0 auto;
}